@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');
@import 'primereact/resources/themes/saga-blue/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';

.p-slidemenu {
  width: 350px !important;
  background: #2d2d33;
  border: none;
}

.p-slidemenu-content {
  height: 500px !important;
}

.p-slidemenu-backward {
  position: relative !important;
  text-align: center;
  span:nth-child(2) {
    color: #f4f4fc;
    font-weight: 500;
    width: 100%;
    display: block;
    padding: 1rem 0;
    transition: all 0.3s ease;
    &:hover {
      background: #02c0f8;
    }
  }
}

.p-menuitem a {
  transition: all 0.3s ease;
  &:hover {
    transform: translateX(0.5rem);
  }
}

.p-inputgroup {
  span,
  input {
    background: rgba(241, 228, 228, 0.2);
    padding: 0.8rem 1.2rem;
  }
  span,
  input {
    border: none !important;
    outline: none !important;
  }
  span {
    color: #02c0f8;
  }
  input {
    color: #f4f4fc;
    &:focus {
      box-shadow: none !important;
    }
  }
  ::placeholder {
    color: rgba(243, 243, 252, 0.6) !important;
    font-weight: 300 !important;
    letter-spacing: 0.1rem;
  }
}

.p-datatable {
  border: 1px solid rgba($color: #f4f4fc, $alpha: 0.1) !important;
  border-bottom: none !important;
  & table {
    table-layout: auto !important;
  }
}

.p-datatable-header {
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
  color: #f4f4fc !important;
  border-bottom: 1px solid rgba($color: #f4f4fc, $alpha: 0.2) !important;
}

.p-datatable-thead {
  th {
    background: #2d2d33 !important;
    color: #f4f4fc !important;
    text-align: center !important;
    border-bottom: 1px solid rgba($color: #f4f4fc, $alpha: 0.2) !important;
  }
}

.p-datatable .p-datatable-tbody > tr {
  background: transparent !important;
  & > td {
    border-bottom: 1px solid rgba($color: #f4f4fc, $alpha: 0.2) !important;
    color: #f4f4fc !important;
    text-align: center;
    font-size: 0.85rem;
    padding: 0.6rem !important;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #f4f4fc;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
}

.p-autocomplete-panel {
  background: #2d2d33;
  border-radius: 0 !important;
}

.p-autocomplete-input {
  width: 100% !important;
  border-radius: 0 !important;
  color: #f4f4fc;
  background: transparent;
  border: none !important;
  outline: none !important;
  padding: 0.8rem 1.2rem;
  &::placeholder {
    color: #f4f4fc;
    font-weight: 300;
    letter-spacing: 0.1rem;
    font-size: 0.9rem;
  }
  &:focus {
    border: none !important;
    outline: none !important;
  }
}

.p-autocomplete-item {
  color: #f4f4fc !important;
  font-size: 0.9rem;
  &:hover {
    background: #f4f4fc !important;
    color: #2d2d33 !important;
  }
}

.p-autocomplete.p-component.p-inputwrapper {
  width: 100% !important;
  border-radius: 0 !important;
  height: 45px !important;
}

.p-calendar {
  height: 45px !important;
  input {
    border: none;
    font-size: 0.9rem !important;
    outline: none;
    box-shadow: none;
    padding: 0.75rem 1.2rem;
    background: transparent;
    color: #f4f4fc !important;
    &:focus {
      border: none;
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: #f4f4fc !important;
      font-weight: 300;
      letter-spacing: 0.1rem;
    }
  }
}

.p-dialog-header,
.p-dialog-content,
.p-dialog-footer {
  background: #2d2d33 !important;
  color: #f4f4fc !important;
  overflow: visible !important;
}

.p-dialog-title {
  color: #f4f4fc;
  text-transform: uppercase;
}

.p-inputwrapper {
  background: rgba(241, 228, 228, 0.2);
  border-radius: 10px;
  border: none;
  font-weight: 300;
}

.p-dropdown {
  height: 45px;
  .p-inputtext {
    color: #f4f4fc !important;
    font-size: 0.9rem !important;
    letter-spacing: 0.1rem !important;
  }
  .p-dropdown-label.p-placeholder {
    color: #f4f4fc;
  }
}

.p-dropdown-items {
  background: #2d2d33 !important;
}

.p-dropdown-item {
  color: #f4f4fc !important;
  background: #2d2d33 !important;
  font-size: 0.9rem;
  &:hover {
    background: #f4f4fc !important;
    color: #2d2d33 !important;
  }
}

.p-dropdown-items-wrapper {
  border: 0 !important;
}

.p-slidemenu-backward-icon {
  display: none !important;
}

.p-radiobutton .p-radiobutton-box {
  .p-radiobutton-icon {
    background: #02c0f8 !important;
    border: none !important;
  }
  &.p-highlight {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
  }
}

.DayPickerInput {
  input {
    background: rgba(241, 228, 228, 0.2);
    padding: 0.82rem 1.2rem !important;
    border-radius: 0 !important;
    border: none;
    outline: none;
    box-shadow: none;
    color: #fff;
    width: 100%;
  }
  &-OverlayWrapper {
    position: absolute;
    z-index: 100;
  }
}

.p-checkbox .p-checkbox-box {
  width: 0.9rem !important;
  height: 0.9rem !important;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 10px !important;
}

.invoice-header {
  text-align: center;
  margin-bottom: 20px;
}

.invoice-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  th, td {
        border: 1px solid black;
        padding: 10px;
        text-align: center;
    }
}

.signature-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  display: none;
}

.invoice-view {
  display: block;
}

.invoice-print {
  display: none;
}

@media print {
  .invoice-print {
    display: block;
    color: #000;
    @page {
        size: A4;
        margin: 0;
    }
  }
  .invoice-header {
    text-align: center;
    margin-bottom: 20px;
  } 
  .invoice-header h1 {
    font-size: 1.5rem;
  }
  .invoice-header p {
    font-size: 1rem; 
  }
  .signature-section {
    display: flex;
  }
}